import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Login

const Login = Loader(lazy(() => import('src/pages/Login')));

// Dashboards pages

const Home = Loader(lazy(() => import('src/pages/dashboard/Home')));

const Finances = Loader(lazy(() => import('src/pages/dashboard/Finances')));

//Players
const Players = Loader(
  lazy(() => import('src/pages/dashboard/Players'))
);
const PlayerAdd = Loader(
  lazy(() => import('src/pages/dashboard/Players/Add'))
);
const PlayerEdit = Loader(
  lazy(() => import('src/pages/dashboard/Players/Edit'))
);

//Tournaments

const Challenges = Loader(
  lazy(() => import('src/pages/dashboard/Challenges'))
);
const ChallengesTeams = Loader(
  lazy(() => import('src/pages/dashboard/Challenges/Teams'))
);
const ChallengesGames = Loader(
  lazy(() => import('src/pages/dashboard/Challenges/Games'))
);
const ChallengesEdit = Loader(
  lazy(() => import('src/pages/dashboard/Challenges/Edit'))
);




const Tournaments = Loader(
  lazy(() => import('src/pages/dashboard/Tournaments'))
);
const TournamentsAdd = Loader(
  lazy(() => import('src/pages/dashboard/Tournaments/Add'))
);
const TournamentsTeams = Loader(
  lazy(() => import('src/pages/dashboard/Tournaments/Teams'))
);
const TournamentsGames = Loader(
  lazy(() => import('src/pages/dashboard/Tournaments/Games'))
);
const TournamentsEdit = Loader(
  lazy(() => import('src/pages/dashboard/Tournaments/Edit'))
);

//Teams
const Teams = Loader(
  lazy(() => import('src/pages/dashboard/Teams'))
);
const TeamsAdd = Loader(
  lazy(() => import('src/pages/dashboard/Teams/Add'))
);
const TeamsEdit = Loader(
  lazy(() => import('src/pages/dashboard/Teams/Edit'))
);

const AdminUsers = Loader(
  lazy(() => import('src/pages/dashboard/AdminUsers'))
);
const AdminUsersAdd = Loader(
  lazy(() => import('src/pages/dashboard/AdminUsers/Add'))
);
const AdminUsersEdit = Loader(
  lazy(() => import('src/pages/dashboard/AdminUsers/Edit'))
);

//Games
const Games = Loader(
  lazy(() => import('src/pages/dashboard/Games'))
);
const GamesAdd = Loader(
  lazy(() => import('src/pages/dashboard/Games/Add'))
);
const GamesEdit = Loader(
  lazy(() => import('src/pages/dashboard/Games/Edit'))
);

//Platforms
const Platforms = Loader(
  lazy(() => import('src/pages/dashboard/Platforms'))
);
const PlatformsAdd = Loader(
  lazy(() => import('src/pages/dashboard/Platforms/Add'))
);
const PlatformsEdit = Loader(
  lazy(() => import('src/pages/dashboard/Platforms/Edit'))
);

//Promocodes
const Promocodes = Loader(
  lazy(() => import('src/pages/dashboard/Promocodes'))
);
const PromocodesAdd = Loader(
  lazy(() => import('src/pages/dashboard/Promocodes/Add'))
);
const PromocodesEdit = Loader(
  lazy(() => import('src/pages/dashboard/Promocodes/Edit'))
);

//TournamentsType
const TournamentsType = Loader(
  lazy(() => import('src/pages/dashboard/TournamentsType'))
);
const TournamentsTypeAdd = Loader(
  lazy(() => import('src/pages/dashboard/TournamentsType/Add'))
);
const TournamentsTypeEdit = Loader(
  lazy(() => import('src/pages/dashboard/TournamentsType/Edit'))
);

//TournamentsMode
const TournamentsMode = Loader(
  lazy(() => import('src/pages/dashboard/TournamentsMode'))
);
const TournamentsModeAdd = Loader(
  lazy(() => import('src/pages/dashboard/TournamentsMode/Add'))
);
const TournamentsModeEdit = Loader(
  lazy(() => import('src/pages/dashboard/TournamentsMode/Edit'))
);

//News
const News = Loader(
  lazy(() => import('src/pages/dashboard/News'))
);
const NewsAdd = Loader(
  lazy(() => import('src/pages/dashboard/News/Add'))
);
const NewsEdit = Loader(
  lazy(() => import('src/pages/dashboard/News/Edit'))
);

//Videos
const Videos = Loader(
  lazy(() => import('src/pages/dashboard/Videos'))
);
const VideosAdd = Loader(
  lazy(() => import('src/pages/dashboard/Videos/Add'))
);
const VideosEdit = Loader(
  lazy(() => import('src/pages/dashboard/Videos/Edit'))
);

//banners
const Banners = Loader(
  lazy(() => import('src/pages/dashboard/Banners'))
);
const BannersAdd = Loader(
  lazy(() => import('src/pages/dashboard/Banners/Add'))
);
const BannersEdit = Loader(
  lazy(() => import('src/pages/dashboard/Banners/Edit'))
);

//Faq
const Faqs = Loader(
  lazy(() => import('src/pages/dashboard/Faqs'))
);
const FaqsAdd = Loader(
  lazy(() => import('src/pages/dashboard/Faqs/Add'))
);
const FaqsEdit = Loader(
  lazy(() => import('src/pages/dashboard/Faqs/Edit'))
);

//banners format
const BannersFormats = Loader(
  lazy(() => import('src/pages/dashboard/BannersFormats'))
);
const BannersFormatsAdd = Loader(
  lazy(() => import('src/pages/dashboard/BannersFormats/Add'))
);
const BannersFormatsEdit = Loader(
  lazy(() => import('src/pages/dashboard/BannersFormats/Edit'))
);

//Products
const Products = Loader(
  lazy(() => import('src/pages/dashboard/Products'))
);
const ProductsAdd = Loader(
  lazy(() => import('src/pages/dashboard/Products/Add'))
);
const ProductsEdit = Loader(
  lazy(() => import('src/pages/dashboard/Products/Edit'))
);

//Orders
const Orders = Loader(
  lazy(() => import('src/pages/dashboard/Orders'))
);
const OrdersView = Loader(
  lazy(() => import('src/pages/dashboard/Orders/View'))
);

const UserProfile = Loader(
  lazy(() => import('src/pages/dashboard/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/pages/dashboard/Users/settings'))
);

// Status pages

const Status404 = Loader(
  lazy(() => import('src/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Login />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'players',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Players />
          },
          {
            path: 'add',
            element: <PlayerAdd />
          },
          {
            path: 'edit',
            element: <PlayerEdit />
          }
          ,
          {
            path: 'view/:id',
            element: <PlayerEdit />
          }
        ]
      },
      {
        path: 'desafios',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Challenges />
          },
          {
            path: 'teams',
            element: <ChallengesTeams />
          },
          {
            path: 'games',
            element: <ChallengesGames />
          },
          {
            path: 'edit',
            element: <ChallengesEdit />
          }
        ]
      },
      {
        path: 'torneios',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Tournaments />
          },
          {
            path: 'teams',
            element: <TournamentsTeams />
          },
          {
            path: 'games',
            element: <TournamentsGames />
          },
          {
            path: 'add',
            element: <TournamentsAdd />
          },
          {
            path: 'edit',
            element: <TournamentsEdit />
          }
        ]
      },
      {
        path: 'banners',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Banners />
          },
          {
            path: 'add',
            element: <BannersAdd />
          },
          {
            path: 'edit',
            element: <BannersEdit />
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Products />
          },
          {
            path: 'add',
            element: <ProductsAdd />
          },
          {
            path: 'edit',
            element: <ProductsEdit />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Orders />
          },
          {
            path: 'view',
            element: <OrdersView />
          }
        ]
      },
      {
        path: 'bannersformats',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <BannersFormats />
          },
          {
            path: 'add',
            element: <BannersFormatsAdd />
          },
          {
            path: 'edit',
            element: <BannersFormatsEdit />
          }
        ]
      }, {
        path: 'faqs',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Faqs />
          },
          {
            path: 'add',
            element: <FaqsAdd />
          },
          {
            path: 'edit',
            element: <FaqsEdit />
          }
        ]
      },
      {
        path: 'equipes',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Teams />
          },
          {
            path: 'add',
            element: <TeamsAdd />
          },
          {
            path: 'edit',
            element: <TeamsEdit />
          }
        ]
      },
      {
        path: 'games',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Games />
          },
          {
            path: 'add',
            element: <GamesAdd />
          },
          {
            path: 'edit',
            element: <GamesEdit />
          }
        ]
      },
      {
        path: 'promocodes',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Promocodes />
          },
          {
            path: 'add',
            element: <PromocodesAdd />
          },
          {
            path: 'edit',
            element: <PromocodesEdit />
          }
        ]
      },
      {
        path: 'platforms',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Platforms />
          },
          {
            path: 'add',
            element: <PlatformsAdd />
          },
          {
            path: 'edit',
            element: <PlatformsEdit />
          }
        ]
      },
      {
        path: 'tournamentstype',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <TournamentsType />
          },
          {
            path: 'add',
            element: <TournamentsTypeAdd />
          },
          {
            path: 'edit',
            element: <TournamentsTypeEdit />
          }
        ]
      },
      {
        path: 'tournamentsmode',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <TournamentsMode />
          },
          {
            path: 'add',
            element: <TournamentsModeAdd />
          },
          {
            path: 'edit',
            element: <TournamentsModeEdit />
          }
        ]
      },
      {
        path: 'noticias',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <News />
          },
          {
            path: 'add',
            element: <NewsAdd />
          },
          {
            path: 'edit',
            element: <NewsEdit />
          }
        ]
      },
      {
        path: 'videos',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Videos />
          },
          {
            path: 'add',
            element: <VideosAdd />
          },
          {
            path: 'edit',
            element: <VideosEdit />
          }
        ]
      },
      {
        path: 'adminusers',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <AdminUsers />
          },
          {
            path: 'add',
            element: <AdminUsersAdd />
          },
          {
            path: 'edit',
            element: <AdminUsersEdit />
          }
        ]
      },
      {
        path: 'finances',
        element: <Finances />
      },
    ]
  }
];

export default routes;
